<template>
  <div
    class="h-screen w-screen flex items-center justify-center fixed top-0 left-0 bg-white z-50"
  >
    <span class="loader"></span>
  </div>
</template>
<script></script>
<style scoped>
.loader {
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
  left: -12px;
  background: #000;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    transform: translate(0, 0) rotateX(0) rotateY(0);
  }
  25% {
    transform: translate(100%, 0) rotateX(0) rotateY(180deg);
  }
  50% {
    transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
  }
  75% {
    transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
  }
  100% {
    transform: translate(0, 0) rotateX(0) rotateY(360deg);
  }
}
</style>
