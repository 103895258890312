<template>
  <div class="grid grid-cols-12 gap-5 px-5 flex w-full h-screen pb-32 pt-16">
    <div
      v-for="(slide, key) in slides"
      :key="key"
      class="fade-in"
      :class="[cols[key], align[key], pad[key]]"
    >
      <ImageAsset
        @mouseover="
          selectedKey = key;
          hover = true;
        "
        @mouseout="hover = false"
        class="max-h-third md:max-h-half"
        :image="slide.fields.images[0]"
      />
    </div>
    <div class="fixed bottom-5 w-full text-center">
      <span v-show="hover" class="opacity-50"
        >{{ artist }}
        <i style="padding-right: 2px" class="inline-block">{{ title }}</i>
        {{ year }}</span
      >
    </div>
  </div>
</template>
<script>
import ImageAsset from "@/components/ImageAsset";
import _ from "lodash";
export default {
  components: {
    ImageAsset,
  },
  props: ["slides"],
  data() {
    return {
      hover: false,
      selectedKey: 0,
      cols: [
        "col-span-12 flex justify-end md:block md:col-span-6 delay-one",
        "col-span-12 flex justify-center md:block md:col-span-3 delay-two",
        "col-span-12 flex justify-start  md:block md:col-span-3 delay-three",
      ],
      align: ["top", "self-center", "self-end"],
      pad: ["", "", ""],
    };
  },
  mounted() {
    this.cols = _.shuffle(this.cols);
    this.align = _.shuffle(this.align);
    this.pad = _.shuffle(this.pad);
  },
  computed: {
    title() {
      return _.get(this.slides[this.selectedKey], "fields.title");
    },
    artist() {
      return _.get(this.slides[this.selectedKey], "fields.artist");
    },
    year() {
      return _.get(this.slides[this.selectedKey], "fields.year");
    },
  },
};
</script>
<style scoped>
.delay-one {
  animation-delay: 0ms !important;
}
.delay-two {
  animation-delay: 0.5s !important;
}
.delay-three {
  animation-delay: 1s !important;
}
.fade-in {
  -webkit-animation: fade-in 10s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 10s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-10-2 2:0:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
